export default {
  cake: {
    56: '0x308929e13891b1079f3bf135c295a916f46ccbb8',
    97: '',
  },
  masterChef: {
    56: '0x75fa651801a8157b598119b3929844025b56e772',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '0xa6e0F7C9f90CE026b91F80F702E08C37dEdf4786',
    97: '',
  },
  lotteryNFT: {
    56: '0x1Fe9551352f3d76496C5d3f89396223164953cFB',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
