import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'http://exp.mashimashiswap.finance/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'http://exp.mashimashiswap.finance/#/add/BNB',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/nests',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xd2B625C5D93F9D87Ddf77DB9c268c0d0bAb1eb41',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/goose-finance',
  //     },
  //     {
  //       label: 'Instagram',
  //       href: 'https://www.instagram.com/hoaxfinance/',
  //     },
  //     {
  //       label: 'Twitter',
  //       href: 'https://twitter.com/VbcFinance',
  //     },
  //   ],
  // },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/hoaxfinance',
  //     },
      // {
      //   label: 'Docs',
      //   href: 'https://goosedefi.gitbook.io/goose-finance/',
      // },
      // {
      //   label: 'Medium',
      //   href: 'https://eclipseprotocol.medium.com/eclipse-protocol-initiates-liftoff-ce3c18ae4c6b',
      // },
  //   ],
  // },
  // {
  //   label: 'Twitter',
  //   icon: 'TwitterIcon',
  //   href: 'https://twitter.com/VbcFinance',
  // },
  // {
  //   label: 'Telegram',
  //   icon: 'TelegramIcon',
  //   href: 'https://t.me/VeniceBeachFinance',
  // },
]

export default config
