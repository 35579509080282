import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'JIRO-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x6dc830be9e61424254508f8bc5109312605f8223',
    },
    tokenSymbol: 'JIRO', 
    tokenAddresses: {
      97: '',
      56: '0x308929e13891b1079f3bf135c295a916f46ccbb8',
    },  
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses:   contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'JIRO-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xbf40f6fb37f9043e61bf1f93dc645895f519252f',
    },
    tokenSymbol: 'JIRO',  
    tokenAddresses: {
      97: '',
      56: '0x308929e13891b1079f3bf135c295a916f46ccbb8',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 4,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'JIRO',
    lpAddresses: {
      97: '',
      56: '0x6dc830be9e61424254508f8bc5109312605f8223', // SPAZ-BUSD LP
    },
    tokenSymbol: 'JIRO',
    tokenAddresses: {
      97: '',
      56: '0x308929e13891b1079f3bf135c295a916f46ccbb8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  
]

export default farms
